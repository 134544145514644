.transactions-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 10px 15px 20px rgba(82, 126, 185, 0.1);
    border-radius: 10px;
    align-items: center;
    padding-top: 30px;
    gap: 50px;
    box-sizing: border-box;
}

.transactions-container-header {
    display: flex;
    flex-direction: column;    
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;

}

.transactions-container-filter-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
}

.transactions-container-filter-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    
    box-sizing: border-box;
}

.transactions-container-filter-inputs-row {
    display: flex;
    gap: 10%;
    width: 100%;
}

.transactions-container-transaction-list {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}