.category-list-item {
    display: flex;
    padding: 20px 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #464C55;
}

.category-list-item-name-container {
    display: flex;
    gap: 20px;
    align-items: center;
}

.category-list-item-name-icon {
    width: 35px;
    height: 38px;
    background-size: 100% 100%;
}

.category-list-item-name-title-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.category-list-item-name-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.category-list-item-name-date {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #969CA4;
}

.category-list-item-price {
    display: flex;
    align-items: center;
    gap: 30px;
}

.category-list-item-price-value {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.category-list-item-price-delete-button {
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    background: url('icons/deleteIcon.svg') 50% 50% no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

.category-list-item-price-delete-button:hover {
    opacity: .7;
}

.category-list-item-name-icon.food {
    background: url('icons/foodIcon.svg') 50% 50% no-repeat;    
}

.category-list-item-name-icon.clothes {
    background: url('icons/clothesIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.auto {
    background: url('icons/autoIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.connections {
    background: url('icons/connectionsIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.entertainment {
    background: url('icons/entertainmentIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.transport {
    background: url('icons/transportIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.health {
    background: url('icons/healtsIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.house {
    background: url('icons/houseIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.salary {
    background: url('icons/salaryIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.investments {
    background: url('icons/investmentsIcon.svg') 50% 50% no-repeat;
}

.category-list-item-name-icon.auto,
.category-list-item-name-icon.health,
.category-list-item-name-icon.transport,
.category-list-item-name-icon.entertainment,
.category-list-item-name-icon.connections,
.category-list-item-name-icon.clothes,
.category-list-item-name-icon.food,
.category-list-item-name-icon.house,
.category-list-item-name-icon.salary,
.category-list-item-name-icon.investments {
    background-size: 100% 100%;
}

@media screen and (max-width: 640px) {
    .category-list-item {
        padding: 20px 10px;
    }
}