.main-expenses-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 54px;
    background: #FFFFFF;
    box-shadow: 10px 20px 20px rgba(82, 123, 185, 0.1);
    border-radius: 10px;
    align-items: center;
    gap: 10px;
    position: relative;
}

.main-expenses-show-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.main-expenses-chart {
    margin-top: auto;
}

.expenses-doughnut-diagram {
    box-sizing: border-box;
    display: flex;
    width: 200px;
}

@media screen and (max-width: 1024px) {
    .main-expenses-container {
        width: 100%;
        height: 340px;        
    }
}

@media screen and (max-width: 640px) {
    .main-expenses-container {
        padding: 20px 15px;
    }
}