.bar-chart-container {
    width: 100%;
    height: 100%;
    padding: 28px 30px 10px 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 10px 15px 20px rgba(82, 126, 185, 0.1);
    border-radius: 10px;
    position: relative;
    
}

.bar-chart-chart {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-x: auto;
}

.bar-chart-chart-help {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 2940px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}