.left-menu-container {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    width: 220px;
    height: 100%;
    box-sizing: border-box;
    background-color: #FFFFFF;
    gap: 50px;
    flex-shrink: 0;
    user-select: none;
    transition: .3s;
}

.left-menu-title {
    padding-left: 15px;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #464C55;
}

.left-menu-burger-button-container {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 21px;
    padding: 0 10px;
    box-sizing: border-box;
}

.left-menu-burger-button {
    width: 30px;
    height: 21px;
    border: none;
    background-color: transparent;
    background: url('./icons/burgerIcon.svg') 50% 50% no-repeat;
    background-size: 100% 100%;
    flex-shrink: 0;
    cursor: pointer;
}

.left-menu-burger-button:hover {
    opacity: .7;
}

.left-menu-category {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.menu-item-container {
    display: flex;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 15px;
    gap: 20px;
    color: #969CA4;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
}

.menu-item-container.exit {
    margin-top: auto;    
}

.menu-item-container:hover {
    opacity: .7;
}

.menu-item-container.active {
    color: #4851FB;
    background-color: #F5F7FB;
    box-shadow: inset 6px -1px 10px rgba(82, 126, 185, 0.1);
}

.menu-item-icon {
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
    flex-shrink: 0;
}

.menu-item-name {

}

.menu-item-icon.summary {
    background: url('icons/summaryIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.summary.active {
    background: url('icons/summaryActiveIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.transactions {
    background: url('icons/transactionIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.transactions.active {
    background: url('icons/transactionActiveIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.goals {
    background: url('icons/goalsIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.goals.active {
    background: url('icons/goalsActiveIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.exit {
    background: url('icons/exitIcon.svg') 50% 50% no-repeat;
}

.menu-item-icon.summary,
.menu-item-icon.summary.active,
.menu-item-icon.transactions,
.menu-item-icon.transactions.active,
.menu-item-icon.goals,
.menu-item-icon.goals.active,
.menu-item-icon.exit {
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
}


.left-menu-container.hidden {
    width: 50px;
    flex-shrink: 0;
    transition: .3s;
}

.left-menu-container.hidden .menu-item-container {
    display: flex;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 10px;
    gap: 20px;
    color: #969CA4;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
}

.left-menu-container.hidden .menu-item-name {
    /* display: none; */
    opacity: 0;
}

.left-menu-container-shadow-help.show-small {
    width: 50px;
    height: 100%;
    flex-shrink: 0;
}

.left-menu-container-background-gray {
    background-color: transparent;
    transition: .3s;
}

.left-menu-container-background-gray.show-small {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    transition: .3s;
    touch-action: none;
}

.left-menu-container.show-small {
    width: 200px;
    position: absolute;
    transition: .3s;
    z-index: 9999999;
}

.left-menu-container.show-small .menu-item-name {
    /* display: inline; */
    opacity: 1;
    transition: .3s;
    font-size: 18px;
}

@media screen and (max-width: 1024px) {
    .left-menu-container {
        min-height: 100vh;
        position: fixed;
        z-index: 40000;
    }

    .left-menu-container.show-small {
        width: 200px;
        position: fixed;
        transition: .3s;
        z-index: 9999999;
    }

    .left-menu-container-shadow-help {
        width: 50px;
        height: 100%;
        flex-shrink: 0;
    }
    
}