.goals-container {
    display: flex;
    box-sizing: border-box;
    padding: 30px 0px 18px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 10px 15px 15px rgba(82, 126, 185, 0.1);
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.goals-container-header {
    box-sizing: border-box;
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
}

.goals-container-header-title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #464C55;
}

.goals-container-header-create-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 12px;
}

.goals-container-header-create-icon {
    width: 20px;
    height: 20px;
    background: url('icons/addGoalIcon.svg') 50% 50% no-repeat;
    background-size: 100% 100%;
}

.goals-container-header-create-caption {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #464C55;
    user-select: none;
}