.setting-goal-container {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 10px 15px 15px rgba(82, 126, 185, 0.1);
    border-radius: 10px;
}

.create-goal-container {
    display: flex;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.title-goal-container {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #464C55;
}

.create-goal-container-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    overflow-y: auto;
    padding-top: 5px;
    box-sizing: border-box;
}

.select-color-item {
    width: 130px;
    height: 30px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.select-color-item.green {
    background-color: #01D9A0;
}

.select-color-item.yellow {
    background-color: #FBF248;
}

.select-color-item.purple {
    background-color: #9B3DFD;
}

.select-color-item.pink {
    background-color: #FB4B81;
    color: #f5f7fbc9;
}

.create-goal-container-buttons {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: auto;
}




.info-goal-container {
    display: flex;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.info-goal-container-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.info-goal-doughnut-diagram {
    width: 220px;
}

.info-goal-container-caption-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info-goal-container-caption-container-fab-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.info-goal-container-caption-container-fab-icon {
    width: 22px;
    height: 22px;
}

.info-goal-container-caption-container-fab-icon.add {
    background: url('icons/addIcon.svg') 50% 50%;
}

.info-goal-container-caption-container-fab-icon.complete {
    background: url('icons/completeIcon.svg') 50% 50%;    
}

.info-goal-container-caption-container-fab-icon.add,
.info-goal-container-caption-container-fab-icon.complete {
    background-size: 100% 100%;
}

.info-goal-container-edit-button-container {
    margin-top: auto;
    width: 100%;
}

.add-sum-modal-content-container {
    width: 330px;
    height: 250px;
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px;
    background: #FFFFFF;
    box-shadow: 10px 20px 15px rgba(72, 81, 251, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    gap: 20px;
    box-sizing: border-box;
}

.add-sum-modal-content-header {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.add-sum-modal-content-header-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #464C55;
    max-width: 200px;
    text-align: center;
}

.add-sum-modal-content-header-close-button {
    position: absolute;
    right: 10px;
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: none;
    background: url('./icons/closeModalIcon.svg') 50% 50% no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

.add-sum-modal-content-header-close-button:hover {
    opacity: .7;
}

.MuiButtonBase-root.aaa {
    margin-top: auto;
}