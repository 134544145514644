.goals-list-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
}

.goals-list-item-container {
    display: flex;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 10px 18px;
    flex-shrink: 0;
}

.goals-list-item-left-part {
    display: flex;
    gap: 25px;
    height: 100%;
}

.goals-list-item-left-part-selected-line {
    height: 100%;
    width: 5px;
    background-color: #969CA4;
}

.goals-list-item-left-part-selected-line.active {
    background-color: #4851FB;
}

.goals-list-item-left-part-title-progress-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.goals-list-item-left-part-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #464C55;
}

.goals-list-item-left-part-progress-container {
    height: 30px;
    width: 120px;
    background: #F5F7FB;
    border-radius: 5px;
    position: relative;
    display: flex;
}

.goals-list-item-left-part-progress-complete-part {
    height: 100%;
    border-radius: inherit;
}

.goals-list-item-left-part-progress-complete-part.green {
    background-color: #01D9A0;
}

.goals-list-item-left-part-progress-complete-part.yellow {
    background-color: #FBF248;
}

.goals-list-item-left-part-progress-complete-part.purple {
    background-color: #9B3DFD;
}

.goals-list-item-left-part-progress-complete-part.pink {
    background-color: #FB4B81;
}

.goals-list-item-right-part {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.goals-list-item-right-part-deadline-date {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #464C55;
}

.goals-list-item-right-part-sum-info {
    display: flex;
    gap: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #464C55;
}