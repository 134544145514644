.transaction-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 10px 15px 20px rgba(82, 126, 185, 0.1);
    border-radius: 10px;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    gap: 30px;
}

.transaction-info-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;

    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #464C55;
}