.info-summary-container {
    width: 100%;
    min-height: 100px;
    max-height: 125px;
    max-height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 15px 0 20px 0;
    position: relative;
    border-radius: 10px;
    user-select: none;    
}

.info-summary-container.button {
    background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 10px 15px 20px rgba(82, 126, 185, 0.1);
    cursor: pointer;
}

.info-summary-container.info {
    background: linear-gradient(280.94deg, #4851FB 8.13%, #8085E6 74.28%, #BEC0EE 126.34%);
    box-shadow: 10px 15px 15px rgba(82, 126, 185, 0.1);
}

.info-summary-container-icon {
    position: absolute;
    width: 28px;
    height: 28px;
    background-size: 100% 100%;    
    left: 20px;
}

.info-summary-container-icon.expenses {
    background: url('icons/expensesIcon.svg') 50% 50% no-repeat;
}

.info-summary-container-icon.income {
    background: url('icons/incomeIcon.svg') 50% 50% no-repeat;
}

.info-summary-container-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    align-items: center;
    height: 100%;
}

.info-summary-container-data.info {
    color: #FFFFFF;
}

.info-summary-container-data.expenses,
.info-summary-container-data.income {
    color: #464C55;
}

.info-summary-container-data-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.info-summary-container-data-value {
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
}

@media screen and (max-width: 1024px) {
    .info-summary-container {
        gap: 12px;
        padding: 20px 10px;
    }

    .info-summary-container-icon {
        width: 25px;
        height: 25px;
        position: relative;
        left: 0;
    }

    .info-summary-container-data-value {
        font-size: 20px;
    }

    .info-summary-container-data {
        gap: 20px;
    }
}