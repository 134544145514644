.expenses-category-container {
    width: 100%;
    height: 100%;
    /* height: 400px; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* padding: 20px 54px; */
    padding-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 10px 20px 20px rgba(82, 123, 185, 0.1);
    border-radius: 10px;
    align-items: center;
    gap: 10px;
    position: relative;
}

.expenses-category-header-container {
    width: 100%;
    padding: 20px 54px 0 54px;
    box-sizing: border-box;
}

.expenses-category-container-list-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 54px;
    box-sizing: border-box;
}

.expenses-category-container-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
    .expenses-category-container {
        width: 100%;
        height: 340px;        
    }
}

@media screen and (max-width: 640px) {
    .expenses-category-header-container {
        padding: 20px 15px;
    }

    .expenses-category-container-list-container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 0 15px;
        box-sizing: border-box;
    }
}