.main-page-summary-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    /* padding: 40px 70px !important; */
    padding: 3.5% 6%;
    position: relative;
}

.main-page-summary-money-summary {
    display: flex;
    width: 100%;
    gap: 20px;
    min-height: 100px;
    /* max-height: 125px; */
    max-height: 10%;
    height: 100%;
}

.main-page-summary-chart-summary {
    width: 100%;
    min-height: 200px;
    /* max-height: 320px; */
    max-height: 40%;
    height: 100%;
}

.main-page-summary-expenses-container {
    display: flex;
    /* flex-direction: column; */
    height: 100%;
    min-height: 340px;
    /* max-height: 400px; */
    max-height: 50%;
    gap: 18px;
    box-sizing: border-box;
}

@media screen and (max-width: 1280px) {
    .main-page-summary-container {       
        padding: 30px 20px;
    }
}

@media screen and (max-width: 1024px) {
    .main-page-summary-container {       
        padding: 30px 20px;
    }

    .main-page-summary-chart-summary {
        /* overflow-x: scroll; */
    }

    .main-page-summary-expenses-container {
        display: flex;
        flex-direction: column;
        
        gap: 20px;
    }

    .main-page-summary-chart-summary {
        width: 100%;       
        height: 190px;
    }
}

@media screen and (max-width: 640px) {
    .main-page-summary-container {
        padding: 30px 12px;
    }

    .main-page-summary-money-summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        min-height: 100px;
        /* max-height: 125px; */
        max-height: 10%;
        height: 100%;
    }
}