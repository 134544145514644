.main-page-transactions-container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* padding: 40px 70px; */
    padding: 3.5% 6%;
    position: relative;
    gap: 20px;
}

.main-page-transactions-right-part {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
}